import React from "react";
import Hero from "./Hero";
import Nav from "./Nav";
import Education from "./Education";
import Credit from "./Credit";
import Contact from "./Contact";
import WorkExperience from "./WorkExperience";
import Skills2 from "./Skills2";

function App() {
  return (
    <div className="bg-blue min-h-screen scroll-smooth items-center flex flex-col">
      <Hero />

      <WorkExperience />

      <Skills2 />
      <Education />
      <Contact />
      <Credit />
      <Nav />
    </div>
  );
}

export default App;
