import React from "react";

function Credit() {
  return (
    <>
      <div className="flex w-full justify-center items-center mx-auto  border-[0px]  border-t-2  rounded-bl-xl rounded-br-xl">
        <div className="text-[#ffffff] font-thin ">
          <h1 className="mt-5 mb-5 ml-5 opacity-50">
            Sa Adat Azam Saniat | 2024 | V3.0
          </h1>
        </div>
      </div>
    </>
  );
}

export default Credit;
