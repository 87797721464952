import mcgill from "./photos/mcgill-university-1.svg";
import { FaAngleDoubleDown } from "react-icons/fa";

function Hero() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen dark:bg-off">
      <div className="flex flex-col items-center justify-center px-10 pt-10 bg-[#ff6f4a] rounded-b-full m-8 w-3/4 md:w-3/4 shadow-inner md:rounded-tl-sm lg:rounded-t-none rounded-t-full ">
        <div className="text-center">
          <span className="font-semibold font-mono text-blue text-3xl">
            Hello, I'm Saniat!
          </span>
          <div className="flex flex-col lg:flex-row gap-2 md:gap-10 text-lg font-mono leading-8 text-space text-left justify-center pt-8">
            <div className="border-t-[10px] pt-5  border-l-[1px] px-6 border-b-[1px] border-r-[1px] pb-4 border-off w-full rounded-lg shadow-md mb-4 text-white bg-blue_50 backdrop-blur-xl">
              <span className="text-5xl">🚀</span> An experienced Full Stack
              Developer and Freelancer with a strong background and focus in Web
              Application and AI Development.
            </div>
            <div className="pt-5 border-r-[1px] px-6 border-t-[10px] border-b-[1px] border-l-[1px] pb-4 border-off w-full rounded-lg shadow-md mb-4 text-white bg-blue_50 backdrop-blur-xl">
              <span className="text-5xl">🔍</span> Currently seeking a full‑time
              opportunity to apply my skills and experience to drive impactful
              solutions within a dynamic team environment.
            </div>
            <div className="border-t-[10px] pt-5 border-l-[1px] px-6 border-b-[1px] border-r-[1px] pb-4 border-off w-full rounded-lg shadow-md mb-4 text-white bg-blue_50 backdrop-blur-xl">
              <div className="m-4">
                <span className="text-5xl">🎓</span>
                Graduated with a Bachelors of Science, Majoring in Computer
                Science from McGill University, Canada.
              </div>
            </div>
          </div>
          <div className="md:block">
            <a
              onClick={() =>
                window.scrollTo({
                  top:
                    document.getElementById("workoo").getBoundingClientRect()
                      .top +
                    window.scrollY +
                    -50,
                  behavior: "smooth",
                })
              }
              className="flex flex-col items-center justify-center pb-2 pt-10 text-[#1c1c1c] hover:scale-125 cursor-pointer transition-all duration-150 ease-out mb-[10vh]"
            >
              <FaAngleDoubleDown className="w-6 h-6 mb-1 animate-bounce fill-blue text-blue" />
              Scroll Down
            </a>
          </div>
        </div>
      </div>
      <div className="h-[50px] w-[100px] rounded-t-full bg-blue lg:mt-[32vh]"></div>
    </div>
  );
}

export default Hero;
