import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { MdOutlineEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";

const Contact = () => {
  const form = useRef();

  const recaptchaRef = useRef();

  const [mailState, setMailState] = useState(null);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (email === "" || name === "" || message === "") {
      setMailState("Please fill out all the fields!");
      return;
    }

    if (!isCaptchaValid) {
      setMailState("Please complete the CAPTCHA to send your message.");
      return;
    }

    emailjs
      .sendForm(
        "service_2g2hcow",
        "template_m38zzbf",
        form.current,
        "g6FbY9h30qmcAuZWG"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMailState("Your message has been sent successfully!");
          form.current.reset();
          recaptchaRef.current.reset();
          setName("");
          setEmail("");
          setMessage("");
          setIsCaptchaValid(false); // Reset CAPTCHA state
        },
        (error) => {
          console.log(error.text);
          setMailState(
            "An error occurred while sending your message. Please try again."
          );
        }
      );
  };

  const onCaptchaChange = (value) => {
    setIsCaptchaValid(!!value);
  };

  return (
    <div
      id="contulee"
      className="relative min-h-screen justify-center bg-off overflow-hidden pb-10 pl-5 pr-5  md:snap-y md:snap-mandatory pt-10 w-full"
    >
      <div className="flex lg:w-1/4 sm:w-1/4 items-center justify-center mx-auto text-center">
        <h1 className="text-4xl font-bold tracking-tight text-blue sm:text-6xl mb-10 mt-10">
          CONTACT
        </h1>
      </div>
      <div className="my-0 flex md:space-x-10 items-center justify-center mx-auto text-center mt-1/6]">
        <div className="w-7/8 md:w-3/4 lg:w-1/2 justify-center align-center bg-blue rounded-xl backdrop-filter backdrop-blur-lg  border-[1px] border-[#24495b]">
          <form ref={form} onSubmit={sendEmail} className="p-4">
            {/* NAME */}
            <div className="mb-6">
              <label
                className="flex text-pink-500 text-base font-thin my-4 font-mono"
                htmlFor="from_name"
              >
                {mailState === "Your message has been sent successfully!" ? (
                  <span className="text-green-500"> {mailState}</span>
                ) : (
                  <p>{mailState}</p>
                )}
              </label>

              <input
                className="shadow-inner border-[1px] border-[#24495b] rounded w-full py-2 px-3 bg-blue font-mono text-orng leading-tight focus:outline-none focus:border-off focus:ring-1 focus:ring-off invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                id="from_name"
                type="text"
                name="from_name"
                placeholder="Your Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* EMAIL */}
            <div className="mb-6">
              <label
                className="block text-[#222222] text-sm  mb-2 font-bold"
                htmlFor="reply_to"
              ></label>

              <input
                className="shadow-inner border-[1px] border-[#24495b] focus:outline-none focus:border-off focus:ring-1 focus:ring-off invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500 bg-blue font-mono text-orng rounded w-full py-2 px-3 leading-tight"
                id="reply_to"
                type="email"
                name="reply_to"
                placeholder="your@email.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* MESSAGE */}
            <div className="mb-6">
              <label
                className="block text-[#222222] text-sm font-bold mb-2"
                htmlFor="message"
              ></label>

              <textarea
                className="shadow-inner border-[1px] border-[#24495b] focus:outline-none focus:border-off focus:ring-1 focus:ring-off invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500 bg-blue font-mono text-orng rounded w-full py-2 px-3 leading-tight"
                id=""
                name="message"
                placeholder="Your message..."
                rows="10"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            {/* SUBMIT */}
            <div className="flex flex-col md:flex-row items-center justify-center">
              <input
                className="bg-[#f9f9f9] border-[1px] border-orng text-blue  hover:bg-orng hover:text-blue hover:animate-pulse shadow-md font-bold py-4 px-8 hover:shadow-inner rounded focus:outline-none focus:shadow-outline cursor-pointer m-10"
                type="submit"
                value="Send"
              />

              <div className="justify-center items-center md:pl-0">
                <ReCAPTCHA
                  sitekey="6LdLEzUpAAAAAK2vNAspB1IyYSm1tbHHC9977W61"
                  // theme="light"
                  ref={recaptchaRef}
                  onChange={onCaptchaChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
