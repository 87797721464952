import React from "react";
import paper from "./photos/logo-paper.svg";
import ResponsibilityModal from "./ResponsibilityModal";
import wl from "./photos/wload.png";
import da from "./photos/dataAnnotation.svg";
import evx from "./photos/logo-evexia-2.png";
import { FaAngleDoubleDown } from "react-icons/fa";

const WorkExperience = () => {
  const TimeLine = ({
    companyName,
    link,
    title,
    title2,
    location,
    period,
    details,
  }) => {
    return (
      <div className="block p-2 m-0 font-mono min-w-[400px] max-w-[460px] max-h-[420px]  min-h-[300px] mx-5">
        <div className="flex items-center justify-between bg-off w-full  px-5 py-4 h-4 text-blue rounded-t-xl font-bold z-50">
          {period}
          <div className="mr-0 float-right">
            <ResponsibilityModal
              photo={companyName}
              title={title}
              title2={title2}
              resps={details}
            />
          </div>
        </div>

        <div className="flex justify-center items-left flex-col shadow-md p-10 rounded-b-xl bg-off  border-solid border-[1px] border-off ml-0">
          <a href={link} target="_blank">
            <img
              src={companyName}
              className={`hover:scale-105 transition-all ease-out duratation-150 mb-3 w-[250px] md:w-[250px] filter grayscale hover:grayscale-0`}
            ></img>
          </a>
          <div className="py-3 pl-2 bg-orng shadow-inner rounded-r-full pr-5 my-3">
            <h2 className="text-semibold font-bold text-lg text-blk">
              🏷️ {title} {title2}
            </h2>

            <h2 className="mt-2">📍 {location}</h2>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="workoo"
      className="flex flex-col justify-center items-center min-h-screen overflow-hidden bg-blue px-8 pt-[0px] pb-0  text-white md:snap-y md:snap-mandatory w-full"
    >
      <div className="h-[50px] w-[100px] rounded-b-full bg-off mt-0 mb-12"></div>
      <h1 className="text-off text-4xl font-bold tracking-tight sm:text-6xl mb-4  sm-1/4 items-center justify-center mx-auto text-center">
        WORK EXPERIENCE
      </h1>
      <div className="md:block mb-10">
        <a
          onClick={() =>
            window.scrollTo({
              top:
                document.getElementById("skills").getBoundingClientRect().top +
                window.scrollY +
                -110,
              behavior: "smooth",
            })
          }
          className="flex flex-col underline items-center justify-center px-4 pb-2 pt-1 text-orng hover:scale-125 cursor-pointer transition-all duration-150 ease-out"
        >
          {/* <FaAngleDoubleDown className="w-6 h-6 mb-1 animate-bounce" /> */}
          Skip To Skills
        </a>
      </div>
      <div className="w-full flex flex-wrap gap-[10px] justify-center">
        <TimeLine
          companyName={
            "https://framerusercontent.com/images/4CIp67iRHdHqzFnhBIJ7iIx7N6M.svg"
          }
          link="https://outlier.ai/"
          title="AI Trainer &"
          title2="Prompt Engineer"
          location="Dhaka, Bangladesh (Remote)"
          period="Oct 2024 - Present • Freelance"
          details={[
            "• Crafting and evaluating complex computer science questions to enhance AI model training, optimizing question relevance and challenge level.",
            "• Evaluating and ranking AI-generated code outputs, assessing accuracy and efficiency to enhance model performance.",
            "• Conducting thorough reviews of peer contributions, providing constructive feedback to uphold quality across collaborative tasks.",
          ]}
        />
        <TimeLine
          companyName={evx}
          link="https://evexia-mh.web.app/"
          title="Full Stack &"
          title2="Brand Developer"
          location="Toronto, ON (Remote)"
          period="Dec 2023 - Sep 2024 • Part-Time"
          details={[
            "• Designed the company logo and established comprehensive brand guidelines to ensure a cohesive visual identity across all digital platforms.",
            "• Engineered and launched the corporate website using React, TypeScript, and TailwindCSS, optimizing for speed and user experience, hosted on Firebase.",
            "• Created bespoke, reusable components tailored to specific functionalities enhancing user interaction and visual appeal.",
            "• Collaborated closely with UI/UX designers to translate Figma designs into functional React Native screens, ensuring high fidelity to original design specifications.",
            "• Developed robust APIs and structuring database schemas using MongoDB, ensuring scalable, secure data management and integration.",
            "• Led cross-functional teams through the ongoing stages of the startup, from concept through to MVP development, focusing on iterative improvements and user feedback incorporation.",
          ]}
        />
        <TimeLine
          companyName={da}
          link="https://www.dataannotation.tech//"
          title="AI Trainer &"
          title2="Prompt Engineer"
          location="Toronto, ON (Remote)"
          period="Jan 2024 - Sep 2024 • Freelance"
          details={[
            "• Trained and enhanced of artificial intelligence models through evaluation, response assessment, and direct interaction.",
            "• Critically analyzed bot responses against several criteria.",
            "• Input model responses for AI learning.",
            "• Employed various programming languages to optimize their coding efficiency.",
            "• Trained models on correctly performing data analysis and visualization by self-analyzing the data using Pandas.",
            "• Reinforced AI models' unit testing capabilities by programming code and its unit tests.",
          ]}
        />
        <TimeLine
          companyName={paper}
          link="https://paper.co/"
          title="STEM Tutor"
          title2={null}
          location="Toronto, ON"
          period="Dec 2022 - Apr 2024 • Part-Time"
          details={[
            "• Oversaw tutoring sessions, elevating students' comprehension in programming languages like JavaScript and Java.",
            "• Collaborated on Slack, achieving a 50% improvement in collective tutoring outcomes.",
            "• Managed real-time debugging, error resolution, and code optimization during mentoring sessions.",
            "• Collaborated with a team remotely using Slack, improving collective tutoring efficacy by 50%.",
          ]}
        />
        <TimeLine
          companyName={wl}
          link="https://www.behance.net/workloadbd/"
          title="Lead Web Developer &"
          title2="Digital Artist"
          location="Dhaka, Bangladesh"
          period="Jun 2020 - Oct 2021 • Part-Time"
          details={[
            "• Led web development initiatives using HTML5, JavaScript, CSS3, and React, resulting in a 50% acceleration in project deliveries.",
            "• Coordinated team efforts via Trello and Zoom, boosting productivity by 75%",
            "• Crafted digital artwork with Adobe Suite, increasing client user engagement by 300%.",
          ]}
        />
      </div>

      <div className="h-[100px] w-[200px] rounded-t-full border-[2px] border-off border-b-0 mt-[10vh] mb-[2.3vh]  z-0"></div>
    </div>
  );
};

export default WorkExperience;
